@use "scss/utils/_vars.scss" as *;

.pol-popup__overlay {
    position: fixed;
    z-index: 9999; // because of admin
    inset: 0;
    background: transparentize($black, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.pol-popup {
    position: relative;
    background: $white;
    border-radius: 4px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 60vh;
    max-width: 60vw;
    min-width: min(350px, 90%);
    overflow-wrap: break-word;
}

.pol-popup__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    font-size: 0.9rem;
    background: $white;
    color: $main-color;
    padding: 0.5rem 1rem;
    font-weight: 500;
    border-bottom: 1px solid $main-color;

    &.is-sticky {
        position: sticky;
        top: 0;
        z-index: 1;
    }
}

.pol-popup__header i {
    cursor: pointer;
    font-size: 1.4rem;
}

.pol-popup__second-title {
    font-size: 1rem;
}

.pol-popup__content {
    padding: 1rem;
    font-size: 0.85rem;
}

.pol-popup__btns {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 1rem 1rem;
    gap: 1.5rem;

    &.is-sticky {
        position: sticky;
        bottom: 0;
        z-index: 1;
        background: $white;
        box-shadow: 0 -1px 4px transparentize($black, 0.8);
    }

    &:empty {
        display: none;
    }
}

.pol-popup__btns .btn {
    flex: 1;
    border-color: var(--principal-color);
    color: var(--principal-color);
    font-weight: bold;
}
