/* input-field */
.input-field-wrapper {
    position: relative;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
}

.input-field-wrapper:not(:last-of-type) {
    margin-bottom: 1rem;
}

.input-field-wrapper .input-field-checkbox-container {
    background-color: #f3f1fc;
    border-radius: 6px;
    padding: 0.6rem 0.8rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
}

.input-field-wrapper .input-field-checkbox-container input,
.input-field-checkboxes-container .input-field-checkbox input {
    height: 1.3rem;
    width: 1.3rem;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    accent-color: #7565c0;
}

.input-field-wrapper .input-field-checkbox-container + label {
    top: 50% !important;
    font-size: 0.8rem !important;
    color: #7565c0 !important;
    pointer-events: unset !important;
    cursor: pointer;
    left: 3.3rem;
    transform: translateY(-50%);
}

.input-field-radio-container, .input-field-checkboxes-container {
    background-color: #f3f1fc;
    border-radius: 6px;
    padding: 0.6rem 0.8rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
}

.input-field-radio-container .input-field-radio {
    display: flex;
    align-items: center;
    column-gap: 0.3rem;
}

.input-field-radio-container .input-field-radio label {
    font-size: 0.85rem;
    color: #a59bd6;
    cursor: pointer;
}

.input-field-radio-container .input-field-radio input:checked + label {
    color: #7565c0;

}

.input-field-radio-container .input-field-radio input {
    appearance: none;
    border-radius: 50%;
    border: solid 2px #a49bd6;
    background: transparent;
    width: 17px;
    aspect-ratio: 1 / 1;
    position: relative;
    cursor: pointer;

    &:checked:before {
        content: '';
        position: absolute;
        inset: 2px;
        border-radius: 50px;
        background: #7565c0;
    }
}

.input-field-checkboxes-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
    gap: 0.5rem;
}

.input-field-checkboxes-container .input-field-checkbox {
    display: flex;
    align-items: center;
    column-gap: 0.3rem;
}

.input-field-checkboxes-container .input-field-checkbox label {
    cursor: pointer;
    user-select: none;
    color: #a59bd6;
}

.input-field-checkboxes-container .input-field-checkbox input:checked + label {
    color: #7565c0;
}

.input-field-wrapper .input-field {
    width: 100%;
    height: 40px;
    padding: 10px;
    font-size: 1.2em;
    border: 0;
    border-radius: 6px;
    background: #f3f1fc;
    color: #7565c0;
    accent-color: #7565c0;

    &:active,
    &:focus {
        outline: none;
    }
}

.input-field-wrapper .input-field[type="range"] {
    padding-inline: 0;
}

.input-field-wrapper .input-field + .range-markers {
    display: flex;
    justify-content: space-between;
    margin-top: -0.5rem;
    font-size: 0.8rem;
    color: #7565c0;
}

.input-field-wrapper .input-field + .range-markers > .range-marker {
    &:not(:is(:first-child, :last-child)) {
        transform: translateX(35%);
    }
}

.input-field-wrapper textarea.input-field {
    resize: vertical;
    min-height: 40px;
}

.input-field-wrapper > .input-field__error + label {
    top: -0.3rem;
}

.input-field-wrapper > .input-field__errors {
    order: 1;
    color: #DC3545;
}

.input-field-wrapper .input-field.has-error,
.input-field-wrapper > .input-field__errors + .input-field-rich-text-editor > div,
.input-field-wrapper > .input-field__errors + .input-field-checkboxes-container,
.input-field-wrapper > .input-field__errors + .react-select .react-select__control {
    outline: 1px solid #DC3545;
    outline-offset: -1px;
}

.input-field-wrapper > label {
    position: absolute;
    left: 1.75rem;
    top: 20px;
    transform: translateY(-50%);
    transition: top .1s linear, font-size .1s linear;
    pointer-events: none;
    font-size: 1.1em;
    color: #a59bd6;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 50px);
    display: block;
    text-overflow: ellipsis;

    &:first-child {
        display: none;
    }
}

.input-field-wrapper > :is(
    .input-field:focus,
    .input-compare.splited,
    *[data-focus="true"]
) + label,
:is(.input-field-radio-container,
.input-field-checkboxes-container,
.input-field-rich-text-editor) + label,
.filepond--wrapper ~ label,
input[type="date"] ~ label,
input[type="datetime-local"] ~ label,
input[type="range"] ~ label,
.input-field-wrapper > label.active {
    top: -0.4rem;
    font-size: 0.9em;
    color: #000;
}

:where(
[data-is-required="true"],
.select-is-required
) ~ label {
    &:after {
        content: '*';
    }
}

.filepond-v4 {
    margin-bottom: unset;
}

.input-display-fields {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.75rem;
}

.input-display-fields > button {
    background: unset;
    border: unset;
    cursor: pointer;
    color: #7565c0;
    font-size: 0.9rem;
    font-weight: bold;
    transition: color .2s ease-in-out;
}

.input-display-fields > button:hover {
    color: #372e74;
}

.input-field-wrapper:has(.react-select--is-disabled) {
    cursor: not-allowed
}

.react-select--is-disabled {
    filter: grayscale(1);
}

.siret-input {
    display: flex;
}

.siret-input .input-field {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.siret-input__button {
    border: none;

    position: relative;
    display: inline-grid;
    place-items: center;
    background: #f3f1fc;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    height: 40px;
    aspect-ratio: 1 / 1;
    cursor: pointer;
    color: #36b43a;
    font-size: 1rem;
    transition: color .2s ease-in-out;

    &:before {
        content: '';
        position: absolute;
        inset-block: 8px;
        right: 100%;
        width: 1px;
        background: hsl(0, 0%, 80%);
    }
}

.siret-input__button:disabled {
    cursor: not-allowed;
    color: #5f5f5f;
}

.siret-input__popup {
    min-width: 60vw;
}

.siret-input__popup-content {
    display: flex;
    flex-direction: column;
}

.siret-input__explanation {
    margin-bottom: 1rem;
}

.siret-input__popup-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

.siret-input__popup-grid .input-field-wrapper > label {
    white-space: normal;
}

.siret-input__popup-content .btn.btn-primary {
    align-self: flex-end;
}

.input-field-rich-text-editor {
    position: relative;
}
