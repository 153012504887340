.error {
    position: fixed;
    background: $error;
    right: 5px;
    bottom: 5px;
    padding: 35px 30px;
    z-index: 7;
    border-radius: 4px;
    color: $white;
    box-shadow: 0 0 5px $box-shadow;
    max-width: 300px;
    text-align: center;
    animation: error .2s linear;
    animation-iteration-count: 1;

    &__close {
        position: absolute;
        right: 10px;
        top: 10px;
        opacity: .5;
        cursor: pointer;

        &:hover {
            opacity: .8;
        }
    }
}

.success {
    position: fixed;
    background: $nav-actions-active;
    right: 5px;
    bottom: 5px;
    padding: 35px 30px;
    z-index: 7;
    border-radius: 4px;
    color: $white;
    box-shadow: 0 0 5px $box-shadow;
    max-width: 300px;
    text-align: center;
    animation: error .2s linear;
    animation-iteration-count: 1;

    &__close {
        position: absolute;
        right: 10px;
        top: 10px;
        opacity: .5;
        cursor: pointer;

        &:hover {
            opacity: .8;
        }
    }
}

.notifications__wrapper {
    position: fixed;
    right: 5px;
    bottom: 5px;
    z-index: 999999;
    max-width: 300px;
}

.alertNotice {
    margin-top: 10px;
    background: #CCC;
    padding: 35px 30px;
    border-radius: 4px;
    color: $white;
    box-shadow: 0 0 5px $box-shadow;
    text-align: center;
    animation: error .2s linear;
    animation-iteration-count: 1;
    position: relative;

    &__close {
        position: absolute;
        right: 10px;
        top: 10px;
        opacity: .5;
        cursor: pointer;

        &:hover {
            opacity: .8;
        }
    }

    &--error {
        background: $error;
    }

    &--warning {
        background: $warning;
        color: $black;
    }

    &--success {
        background: $nav-actions-active;
    }

    &--info {
        background: $info;
    }
}
