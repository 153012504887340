.page-error {
    background: $white;
    padding: 80px 30px;
    text-align: center;

    &-global {
        height: 100vh;
    }

    &-component {
        background: $white;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 50px 0;
        overflow: hidden;

        &.error-absolute {
            padding: 0;
            min-height: 250px;

            &__content {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
            }
        }

        .error-icon {
            font-size: 3.5em;
            color: $main-color;
            opacity: .9;
            box-shadow: 0 0 15px $page404-color4;
            border-radius: 50px;
        }

        .error-text {
            margin-top: 15px;
        }

        &__content {
            text-align: center;
            padding: 0 15px;
        }
    }

    .page {
        &__title {
            text-align: center;
            text-transform: uppercase;
            font-size: 8em;
            font-weight: 700;
            color: $main-color;
            line-height: 1em;
            margin-bottom: 15px;
            text-shadow: 0 1px 0 $page404-color1,
            0 3px 0 $page404-color2,
            0 4px 0 $page404-color3,
            0 5px 0 $page404-color4,
            0 6px 1px $page404-color5,
            0 0 5px $page404-color5,
            0 1px 3px $page404-color9,
            0 3px 5px $page404-color7,
            0 5px 10px $page404-color8,
            0 10px 10px $page404-color7,
            0 20px 20px $page404-color6;
        }

        &__sub-title {
            text-align: center;
            text-transform: uppercase;
            font-size: 1.5em;
            font-weight: 700;
            color: $main-color;
        }

        &__link {
            margin-top: 25px;
            display: inline-block;
            font-size: 1.1em;
            color: $secondary-color;
            padding: 0 15px;

            &__arrow {
                margin-left: 5px;
                animation: horizontalMove 1.5s linear infinite;
            }
        }
    }
}
