@use "scss/utils/_vars.scss" as *;

.documents-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(400px, 100%), 1fr));
    gap: 0.5rem;
    padding: 0.5rem;
}

.documents-list .document {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: space-between;
    column-gap: 0.4rem;
    align-items: center;
    background: #f3f1fc;
    border-radius: 4px;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
}

.documents-list .document .file-icon {
    font-size: 1.5rem;
    color: #7565c0;
    margin-right: 0.4rem;
    vertical-align: middle;
}

.documents-list .document-label {
    font-size: 0.85rem;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
}

.documents-list .document-icon {
    font-size: 1rem;
    color: $main-color;
    cursor: pointer;
    margin-left: 5px;
    border: solid 1px $main-color;
    border-radius: 4px;
    padding: 5px 0;
    text-align: center;
    width: 32px;
    height: 32px;
    line-height: 1;

    &:not(.disabled):hover {
        background-color: $main-color;
        color: $white;
    }

    &.disabled {
        cursor: not-allowed;
        background-color: $disabled;
        color: $disabled-font-color;
        border-color: $disabled;
    }
}

.documents-list .document-delete {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(25%, -25%);
    width: 1rem;
    aspect-ratio: 1;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 0.8rem;
    background: $error;
    color: $white;
    cursor: pointer;
    transition: transform 0.2s;

    &:hover {
        transform: translate(25%, -25%) scale(1.1);
    }
}
