.popup {
    &__input{
        width: 100%;
        margin-bottom: 5px;
    }
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $black;
        opacity: .3;
        z-index: 99999;
        cursor: pointer;
        backdrop-filter: blur(5px);

        @include respond-to('mobile') {
            opacity: .3;
            z-index: 3;
        }

        &-ged {
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: $black;
            opacity: .85;
            top: 0;
            left: 0;
            border-radius: 15px;
        }
    }

    &__content {
        z-index: 99999;
        position: fixed;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        left: 50%;
        background: $white;
        padding: 30px;
        text-align: center;
        border-radius: 4px;
        width: 90%;
        overflow: auto;

        &__title {
            font-size: 1.2em;
            text-transform: uppercase;
            color: $main-color;
            margin-bottom: 25px;
        }

        &__normal-title {
            font-size: 1.2em;
            color: $main-color;
            margin-bottom: 25px;
        }

        .btn-third {
            font-weight: bold;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            height: 40px;
            width: 100%;
            font-size: 1.050em;
            border-radius: 5px;
            border: solid 1px $white;
            color: $white;
            background-color: $active-class;
            transition: .20s ease-in-out;

            &:hover {
                background-color: $white;
                color: $active-class;
                border-color: $active-class;
            }

            &.decline {
                border: solid 1px $active-class;
                color: $active-class;
                background-color: $white;

                &:hover {
                    background-color: $active-class;
                    color: $white;
                }
            }

            &-file:disabled {
                color: $disabled-font-color;
                background-color: $disabled;
            }
        }
    }

    &__date-compare, &__duplicate {
        overflow: unset;
    }

    &__separator{
        margin-top: 20px;
        width: 400px;
    }
    &__export {
        &-img{
            width: 100%;
            min-width: 50%;
          padding: 40px;
        }
    }

    &__forgot{
        .page-details .data__info{
            width: 100px;
        }
        .page-details .data__info__text {
            white-space: normal;
        }
        .page-details {
            padding: 25px;
            .data__prospect__container {
                background: none;
            }
        }
        overflow: visible;
        width: 40vw !important;
        padding: 0;
        &-img{
            width: 43%;
            margin-left: 30px;
        }
        &-form {
            margin-left: 50px;
            width: 50%;
            margin-right: 25px;
        }
        &-content{
            display: flex;
            flex-direction: row;
            min-height: 350px;
            overflow: visible;
            align-items: center;
            justify-content: center;
        }
    }

    &__header {
        background-color: $secondary-color;
        &__title {
            color: $white;
            font-size: 1.5em;
            font-weight: 400;
            padding: 30px;
        }
    }

    &__buttons {
        &-m {
            margin-top: 15px;
        }
        padding: 20px;
    }

    &__footer-btns{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 50px;
        margin-top: 50px;
        .btn {
            width: 100px;
            margin-right: 30px;
            height: 30px;
            border-radius: 0;
        }
    }
}

@include respond-to('tablet') {
    .popup {
        &__content {
            width: auto;
        }
    }
}

@include respond-to('desktop-max') {
    .popup {

        &__forgot {
            overflow: scroll;
            width: 80vw !important;
            margin-top: 25px;
            height: 80%;
            &-img{
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 25px;
                margin-top: 40px;
            }
            &-form {
                width: 100%;
                padding: 0;
                margin-left: 0;
                margin-right: 0;
            }
            &-content{
                .popup__export-img {
                    background-color: red;
                    display: none;
                }
                display: flex;
                flex-direction: column;
                padding: 15px;
                height: 500px;
            }
        }
    }
}
