@use "scss/utils/_vars.scss" as *;

.pol-dropdown {
    position: relative;
    display: inline-block;
}

.pol-dropdown__content {
    position: absolute;
    z-index: 999;
    top: 100%;
    display: inline-block;
    min-width: 100%;
}

.pol-dropdown:not(.is-hoverable) .pol-dropdown__content:not(.is-open) {
    display: none;
}

.pol-dropdown.is-hoverable:not(:hover) .pol-dropdown__content {
    display: none;
}

.pol-dropdown__details {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: $pol-shadow;
}

.pol-dropdown__details-title {
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
    font-weight: bold;
    color: $main-color;
}

.pol-dropdown__details-item {
    white-space: nowrap;
    padding: 0.5rem;
    font-size: 0.85rem;
    cursor: pointer;

    &:hover {
        background: $list-hover
    }
}
