.datatable tbody {
    position: relative;
    isolation: isolate;
}

.ui-table-wrapper {
    position: relative;
    height: 100%;
}

.listing-table-tbody__loader {
    text-align: center !important;
    position: absolute;
    z-index: 3;
    inset: 40px 0 0; /* 40px is the height of the table header */
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.listing-table-tbody__loader > div {
    width: 12rem;
    margin-inline: auto;
}

.listing-table-tbody__loader > div svg {
    overflow: visible;
}

.datatable .empty td:not(.listing-table-tbody__loader):first-of-type,
.datatable td#table-error {
    text-align: center;
    padding-bottom: 15vw;
    cursor: unset;
}

.datatable .empty {
    height: 15rem;
}

.table-v4 tbody .actions-column .btn {
    width: 2rem;
    line-height: 0;
    padding: 0.5rem;
    aspect-ratio: 1;
}

.datatable.table-v4 tbody td {
    cursor: auto;
}
