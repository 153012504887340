.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0.75rem 1rem;
    line-height: 1;
    border-radius: 5px;
    border: solid 1px #000;
    background-color: transparent;
    @include transition(background-color .2s, color .2s);

    + .btn {
        margin-left: 10px;
    }

    i {
        font-size: 0.9rem;
    }

    &:hover {
        background-color: #000;
        color: #fff;
        cursor: pointer;
    }

    &-primary:not(.btn-full) {
        border-color: $main-color;
        color: $main-color;

        &:is(:hover, .is-active) {
            background-color: $main-color;
            color: $white;
    }
  }

  &-primary.btn-full {
    background: $main-color;
    color: $white;

    &:is(:hover, .is-active) {
      background-color: transparent;
      color: $main-color;
        }
    }

    &-secondary {
        border-color: $secondary-color;
        color: $secondary-color;

        &:is(:hover, .is-active) {
            background-color: $secondary-color;
            color: $main-color;
        }
    }

    &-danger {
        border-color: $error;
        color: $error;

        &:is(:hover, .is-active) {
            background-color: $error;
            color: $white;
        }
    }

  &:disabled {
    background-color: $disabled;
    color: $disabled-font-color;
    border-color: $disabled;
    cursor: not-allowed;
  }
}

