.user-list__container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}

.user-list {
    position: absolute;
    top: var(--caret-top);
    left: var(--caret-right);
    right: calc(var(--caret-left) * -1 + 2ch);

    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 0;
    min-width: max-content;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow: auto;
    overscroll-behavior: contain;
}

.user-list .user-list__search {
    font: inherit;
    border: none;
    padding: 0.4rem;

    &:focus-visible {
        outline: none;
    }
}

.user-list:focus-within .user-list__search:focus + .user-list__item {
    background-color: #CCC4F3;
}

.user-list .user-list__item {
    padding: 0.4rem;
    cursor: pointer;

    &:is(:hover, :focus) {
        background-color: #CCC4F3;
    }

    &:focus-visible {
        outline-color: #9589c0;
    }
}
