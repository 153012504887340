@use "scss/utils/_vars.scss" as *;

:where(.table-thead th) { // where is to have a small priority
    background-color: $table-header;
    color: $white;
}

.table-thead th {
    height: 40px;
}

.table-thead th.sortable span {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 8px;
    gap: 0.4em;
    cursor: pointer;
}

.table-thead th.sortable span:before,
.table-thead th.sortable span:after {
    --_font-size: 0.8em;
    position: absolute;
    grid-column: 2;
    font-size: var(--_font-size);
    line-height: 0;
}

.table-thead th.sortable span:before {
    content: "▲";
    top: calc(var(--_font-size) / 2);
}

.table-thead th.sortable span:after {
    content: "▼";
    bottom: calc(var(--_font-size) / 2);
}

.table-thead th.sorted[data-order=asc] span:after,
.table-thead th.sorted[data-order=desc] span:before {
    opacity: 0.5;
}
